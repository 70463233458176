import React from "react";
import loginCSS from "../CSS/admin_login.module.css";
import university from "../FILES/university.jpg";

import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const UniversityLogin = () => {
  return (
    <div className={`${loginCSS["login-body"]}`}>
      <img
        src={university}
        alt="University"
        className={loginCSS["background-image"]}
      />
      <div className={`${loginCSS["login-container"]}`}>
        <Link to="/" className={loginCSS["homepage-button"]}>
          <br />
          <button>Homepage</button>
        </Link>
        <br />
        <h1 className={`${loginCSS["login-h1"]}`}>University Login</h1>
        <form
          action="/login"
          method="post"
          className={`${loginCSS["login-form"]}`}
        >
          <input
            className={`${loginCSS["login-inputField"]}`}
            type="text"
            name="username"
            placeholder="Username"
            required
          />
          <input
            className={`${loginCSS["login-inputField"]}`}
            type="password"
            name="password"
            placeholder="Password"
            required
          />
          <button type="submit" className={loginCSS["login-button"]}>
            Login
          </button>
        </form>
        <Link to="/admin_admin" className={loginCSS["homepage-button"]}>
          <button>Admin Access</button>
        </Link>
        <br />
        <br />
        <Link to="/student_dashboard" className={loginCSS["homepage-button"]}>
          <button>Login as Student</button>
        </Link>
        <br />
        <br />
        <Link to="/instructor_dashboard"
          className={loginCSS["homepage-button"]}
        >
          <button>Login as Instructor</button>
        </Link>
        <br />
        <br />
        <Link to="/pg_dashboard" className={loginCSS["homepage-button"]}>
          <button>Login as Program Coordinator</button>
        </Link>
        <br />
        <br />
        <Link to="/qa_dashboard" className={loginCSS["homepage-button"]}>
          <button>Login as QA Officer</button>
        </Link>
        <br />
        <br />
        <Link to="/forgetpassword" className={loginCSS["login-a"]}>
          Forget Password?
        </Link>
        <br />
        <p>
          Don't have an account?
          <Link to="/signup" className={loginCSS["login-a"]}>
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default UniversityLogin;
