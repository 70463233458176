import React from "react";
import servicesCSS from "../CSS/admin_services.module.css";
import library from "../FILES/library.png";
import Counseling from "../FILES/Counseling.png";
import studentclub from "../FILES/studentclub.png";
import reasearch from "../FILES/research.png";
import health from "../FILES/health.png";
import studyabroad from "../FILES/studyabroad.png";
import tutor from "../FILES/tutor.png";
import acadmicadvicing from "../FILES/acadmicadvicing.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const AdminServices = () => {
  return (
    <div className={`${servicesCSS["serv-body"]}`}>
      <div className={`${servicesCSS["navbar"]}`}>
        <span className={`${servicesCSS["navbar-title"]}`}>AcademiaHub</span>
        <Link to="/login">Log in</Link>
        <Link to="/">Home Page</Link>
        <Link to="/aboutus">About Us</Link>
        <Link to="/contactus">Contact Us</Link>
      </div>
      <h2 className={`${servicesCSS["services-h2"]}`}>Our Services</h2>
      <main>
        <section className={`${servicesCSS["services"]}`}>
          <table className={`${servicesCSS["services-table"]}`}>
            <tbody>
              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={acadmicadvicing}
                    alt="Academic Advising"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Academic Advising</h3>
                  <p>
                    Our experienced advisors help students navigate their
                    academic journey and make informed choices.
                  </p>
                </td>
              </tr>
              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={library}
                    alt="library"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Library Resources</h3>
                  <p>
                    Access a vast collection of books, journals, and digital
                    resources in our modern library.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={Counseling}
                    alt="counseling"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Career Counseling</h3>
                  <p>
                    Prepare for your future career with guidance from our career
                    counseling experts.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={studentclub}
                    alt="studentclub"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Student Clubs</h3>
                  <p>
                    Join a variety of student clubs and organizations to enhance
                    your extracurricular experience.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={reasearch}
                    alt="Research Opportunities"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Research Opportunities</h3>
                  <p>
                    Engage in groundbreaking research projects led by our
                    renowned faculty members.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={health}
                    alt="Health Services"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Health Services</h3>
                  <p>
                    Access healthcare services, counseling, and wellness
                    programs to support your well-being.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={studyabroad}
                    alt="Study Abroad Programs"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Study Abroad Programs</h3>
                  <p>
                    Explore the world and gain international experience through
                    our study abroad programs.
                  </p>
                </td>
              </tr>

              <tr>
                <td className={`${servicesCSS["services-td"]}`}>
                  <img
                    src={tutor}
                    alt="Tutoring and Workshops"
                    className={`${servicesCSS["service-image"]}`}
                  />
                </td>
                <td className={`${servicesCSS["services-td"]}`}>
                  <h3>Tutoring and Workshops</h3>
                  <p>
                    Get academic support through tutoring and attend workshops
                    to enhance your skills.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default AdminServices;
