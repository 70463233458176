import React from "react";
import infoCSS from "../CSS/admin_qaofficer1.module.css";
import penImage from "../FILES/pen.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const QAOfficerInformation2 = () => {
  return (
    <div className={infoCSS["info1-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>
          QA officer's Information
        </span>
        <Link to="/admin_qaofficersinfo">All QA officer's Info</Link>
      </div>
      <br />
      <Link to="#">
        <button className={infoCSS["info1-button"]}>Edit</button>
      </Link>
      <br />
      <table className={infoCSS["qa-info"]}>
        <br />
        <div>
          <img src={penImage} alt="Edit" />
          <p>
            <span className={infoCSS["info1-label"]}>Name:</span> Sarah Smith
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>User ID:</span> 98765
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Access:</span> Allocated
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Address:</span>456 Maple
            Lane, Townsville, State
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Email:</span>{" "}
            sarah.smith@example.com
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Phone:</span> (555)
            987-6543
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>
              College Joining Date:
            </span>{" "}
            March 5, 2019
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Years of Experience:</span>{" "}
            4
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Ratings:</span> 9
          </p>
        </div>
      </table>
    </div>
  );
};

export default QAOfficerInformation2;
