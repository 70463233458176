import React from "react";
import studentconcernCSS from "../CSS/pg_studentconcerns.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentConcerns = () => {
  return (
    <div className={`${studentconcernCSS["conc-body"]}`}>
      <div className={`${studentconcernCSS["navbar"]}`}>
        <span className={`${studentconcernCSS["navbar-title"]}`}>
          Student Concerns
        </span>
        <Link to="/pg_dashboard">Go to Dashboard</Link>
      </div>
      <div className={`${studentconcernCSS["studentconcern-container"]}`}>
        <ul className={`${studentconcernCSS["queries-list"]}`}>
          <li>
            <span className={`${studentconcernCSS["query"]}`}>
              Inquiry 1: Can you provide information about the program
              curriculum?
            </span>
            <button className={`${studentconcernCSS["resolve-button"]}`}>
              Resolve
            </button>
          </li>
          <li>
            <span className={`${studentconcernCSS["query"]}`}>
              Inquiry 2: What are the deadlines for course registration?
            </span>
            <button className={`${studentconcernCSS["resolve-button"]}`}>
              Resolve
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StudentConcerns;
