import React from "react";
import infoCSS from "../CSS/admin_instructor1.module.css";
import penImage from "../FILES/pen.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const InstructorInformation = () => {
  return (
    <div className={infoCSS["info1-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>
          Instructor's Information
        </span>
        <Link to="/admin_instructorsinfo">All instructor's Info</Link>
      </div>
      <br />
      <Link to="/#">
        <button className={infoCSS["info1-button"]}>Edit</button>
      </Link>
      <br />
      <table className={infoCSS["instructor-info"]}>
        <br />
        <div>
          <img src={penImage} alt="Edit" />
          <p>
            <span className={infoCSS["info1-label"]}>Name:</span> Orren Johnson
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>User ID:</span> 67890
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Access:</span>Allocated
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Address:</span>456 Elm
            Street, Town, Province
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Email:</span>{" "}
            orren.johnson@example.com
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Phone:</span> (555)
            987-6543
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>
              College Joining Date:
            </span>{" "}
            May 15, 2018
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Years of Experience:</span>{" "}
            12
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Ratings:</span> 9
          </p>
        </div>
      </table>
    </div>
  );
};

export default InstructorInformation;
