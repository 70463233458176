import React from "react";
import performanceCSS from "../CSS/instructor_student_performance.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const Std_Performance = () => {
  return (
    <div className={`${performanceCSS["perf-body"]}`}>
      <div className={`${performanceCSS["navbar"]}`}>
        <span className={`${performanceCSS["navbar-title"]}`}>Performance</span>
        <Link to="/instructor_dashboard">Go to Dashboard</Link>
      </div>
      <div className={`${performanceCSS["dashboard"]}`}>
        <div className={`${performanceCSS["report"]}`}>
          <h2 className={`${performanceCSS["performance-h2"]}`}>
            Student Performance Report
          </h2>
          <table className={`${performanceCSS["performance-table"]}`}>
            <thead>
              <tr>
                <th className={`${performanceCSS["performance-th"]}`}>
                  Student Name
                </th>
                <th className={`${performanceCSS["performance-th"]}`}>Grade</th>
                <th className={`${performanceCSS["performance-th"]}`}>
                  Attendance
                </th>
                <th className={`${performanceCSS["performance-th"]}`}>
                  Feedback
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={`${performanceCSS["performance-td"]}`}>
                  Student 1
                </td>
                <td className={`${performanceCSS["performance-td"]}`}>A</td>
                <td className={`${performanceCSS["performance-td"]}`}>90%</td>
                <td className={`${performanceCSS["performance-td"]}`}>
                  <Link to="/instructor_feedback"
                    className={`${performanceCSS["feedback-button"]}`}
                  >
                    Give Feedback
                  </Link>
                </td>
              </tr>
              <tr>
                <td className={`${performanceCSS["performance-td"]}`}>
                  Student 2
                </td>
                <td className={`${performanceCSS["performance-td"]}`}>B</td>
                <td className={`${performanceCSS["performance-td"]}`}>85%</td>
                <td className={`${performanceCSS["performance-td"]}`}>
                  <Link to="/instructor_feedback"
                    className={`${performanceCSS["feedback-button"]}`}
                  >
                    Give Feedback
                  </Link>
                </td>
              </tr>
              <tr>
                <td className={`${performanceCSS["performance-td"]}`}>
                  Student 3
                </td>
                <td className={`${performanceCSS["performance-td"]}`}>C</td>
                <td className={`${performanceCSS["performance-td"]}`}>70%</td>
                <td className={`${performanceCSS["performance-td"]}`}>
                  <Link to="/instructor_feedback"
                    className={`${performanceCSS["feedback-button"]}`}
                  >
                    Give Feedback
                  </Link>
                </td>
              </tr>
              <tr>
                <td className={`${performanceCSS["performance-td"]}`}>
                  Student 4
                </td>
                <td className={`${performanceCSS["performance-td"]}`}>B</td>
                <td className={`${performanceCSS["performance-td"]}`}>92%</td>
                <td className={`${performanceCSS["performance-td"]}`}>
                  <Link to="/instructor_feedback"
                    className={`${performanceCSS["feedback-button"]}`}
                  >
                    Give Feedback
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={`${performanceCSS["chart"]}`}>
          <h2>Performance Bar Chart</h2>
          <div className={`${performanceCSS["bar"]}`}>Student 1(90%)</div>
          <div className={`${performanceCSS["bar"]}`}>Student 2(85%)</div>
          <div className={`${performanceCSS["bar"]}`}>Student 3(70%)</div>
          <div className={`${performanceCSS["bar"]}`}>Student 4(92%)</div>
        </div>
      </div>
    </div>
  );
};

export default Std_Performance;
