import React from "react";
import chatCSS from "../CSS/qa_chat.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const QA_ChatPage = () => {
  return (
    <div>
      <div className={chatCSS["navbar"]}>
        <span className={chatCSS["navbar-title"]}>CHAT</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={chatCSS["chat-container"]}>
        <div className={chatCSS["chat-box"]}>
          <div className={chatCSS["chat-header"]}>
            QA Officer - Instructor Chat
          </div>
          <div
            className={`${chatCSS["chat-messages"]} ${chatCSS["instructor-chat"]}`}
          >
            <div className={`${chatCSS["message"]} ${chatCSS["received"]}`}>
              Hello! How can I assist you today?
            </div>
            <div className={`${chatCSS["message"]} ${chatCSS["sent"]}`}>
              Hi, I have added a new course in the Program.
            </div>
          </div>
          <div className={chatCSS["chat-input"]}>
            <input
              type="text"
              placeholder="Type your message"
              id="instructor-message"
            />
            <button>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QA_ChatPage;
