import React from "react";
import examCSS from "../CSS/student_examschedule.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

function StudentExamSchedule() {
  return (
    <div className={examCSS["exam-body"]}>
      <div className={examCSS["navbar"]}>
        <span className={examCSS["navbar-title"]}>Exam Schedule</span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <h1 className={examCSS["exam-h1"]}>Exam Schedule</h1>
      <table className={examCSS["exam-table"]}>
        <thead>
          <tr>
            <th className={examCSS["exam-th"]}>Exam</th>
            <th className={examCSS["exam-th"]}>Topics</th>
            <th className={examCSS["exam-th"]}>Availability</th>
            <th className={examCSS["exam-th"]}>Status</th>
            <th className={examCSS["exam-th"]}>Result Status</th>
            <th className={examCSS["exam-th"]}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 1</td>
            <td className={examCSS["exam-td"]}>
              Html Basics
              <br />
              Tables and Forms
            </td>
            <td className={examCSS["exam-td"]}>09-20-2023 to 09-23-2023</td>
            <td
              className={`${examCSS["status-submitted"]} ${examCSS["exam-td"]}`}
            >
              Submitted
            </td>
            <td className={examCSS["exam-td"]}>Posted</td>
            <td className={examCSS["exam-td"]}>
              <Link to="/#" title="68/100 Lowest: 27.5 Mean: 60.7 High: 94.0">
                View Result
              </Link>
            </td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 2</td>
            <td className={examCSS["exam-td"]}>CSS Basics Layout</td>
            <td className={examCSS["exam-td"]}>09-23-2023 to 09-25-2023</td>
            <td
              className={`${examCSS["status-submitted"]} ${examCSS["exam-td"]}`}
            >
              Submitted
            </td>
            <td className={examCSS["exam-td"]}> Not Posted</td>
            <td className={examCSS["exam-td"]}> -</td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 3</td>
            <td className={examCSS["exam-td"]}>Java Script Fundamentals</td>
            <td className={examCSS["exam-td"]}>09-25-2023 to 09-27-2023</td>
            <td
              className={`${examCSS["status-not-submitted"]} ${examCSS["exam-td"]}`}
            >
              Not Submitted
            </td>
            <td className={examCSS["exam-td"]}>- </td>
            <td className={examCSS["exam-td"]}>
              <button className={examCSS["exam-button"]}>Take Exam</button>
            </td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 4</td>
            <td className={examCSS["exam-td"]}>Java Script 2 Javascript 3</td>
            <td className={examCSS["exam-td"]}>10-01-2023 to 10-05-2023</td>
            <td
              className={`${examCSS["status-not-submitted"]} ${examCSS["exam-td"]}`}
            >
              Not Submitted
            </td>
            <td className={examCSS["exam-td"]}> -</td>
            <td className={examCSS["exam-td"]}>
              <button className={examCSS["exam-button"]}>Take Exam</button>
            </td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 5</td>
            <td className={examCSS["exam-td"]}>
              React Server Side Programming: PHP
            </td>
            <td className={examCSS["exam-td"]}>10-07-2023 to 10-09-2023</td>
            <td
              className={`${examCSS["status-not-submitted"]} ${examCSS["exam-td"]}`}
            >
              Not Open
            </td>
            <td className={examCSS["exam-td"]}> -</td>
            <td className={examCSS["exam-td"]}> -</td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 6</td>
            <td className={examCSS["exam-td"]}>
              Server side development: Node.js
            </td>
            <td className={examCSS["exam-td"]}>10-11-2023 to 10-15-2023</td>
            <td
              className={`${examCSS["status-not-submitted"]} ${examCSS["exam-td"]}`}
            >
              Not Open
            </td>
            <td className={examCSS["exam-td"]}>-</td>
            <td className={examCSS["exam-td"]}> -</td>
          </tr>
          <tr>
            <td className={examCSS["exam-td"]}>Exam 7</td>
            <td className={examCSS["exam-td"]}>Working with databases</td>
            <td className={examCSS["exam-td"]}>10-20-2023 to 10-25-2023</td>
            <td
              className={`${examCSS["status-not-submitted"]} ${examCSS["exam-td"]}`}
            >
              Not Open
            </td>
            <td className={examCSS["exam-td"]}>-</td>
            <td className={examCSS["exam-td"]}> -</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StudentExamSchedule;
