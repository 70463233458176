import React from "react";
import ReportCSS from "../CSS/qa_report.module.css";
import { Link } from "react-router-dom";

function QA_REPORT() {
  return (
    <div className={ReportCSS["rep-body"]}>
      <div className={ReportCSS["navbar"]}>
        <span className={ReportCSS["navbar-title"]}>Report</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div class="container">
        <div class="report-section">
          <h1>Course Mean Report</h1>
          <table className={ReportCSS["report-table"]}>
            <thead>
              <tr>
                <th className={ReportCSS["report-th"]}>Exam</th>
                <th className={ReportCSS["report-th"]}>
                  Total Number of Students
                </th>
                <th className={ReportCSS["report-th"]}>
                  Total Students Attended the Exam
                </th>
                <th className={ReportCSS["report-th"]}>Positive Feedback</th>
                <th className={ReportCSS["report-th"]}>Negative Feedback</th>
                <th className={ReportCSS["report-th"]}>Feedback</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={ReportCSS["report-td"]}>Exam-1</td>
                <td className={ReportCSS["report-td"]}>65</td>
                <td className={ReportCSS["report-td"]}>59</td>
                <td className={ReportCSS["report-td"]}>80%</td>
                <td className={ReportCSS["report-td"]}>20%</td>
                <td className={ReportCSS["report-td"]}>
                  <Link to="/qa_instructorfeedback">
                    <button>Feedback</button>{" "}
                  </Link>
                </td>
              </tr>
              <tr>
                <td className={ReportCSS["report-td"]}>Exam-2</td>
                <td className={ReportCSS["report-td"]}>70</td>
                <td className={ReportCSS["report-td"]}>63</td>
                <td className={ReportCSS["report-td"]}>70%</td>
                <td className={ReportCSS["report-td"]}>30%</td>
                <td className={ReportCSS["report-td"]}>
                  <Link to="/qa_instructorfeedback">
                    <button>Feedback</button>{" "}
                  </Link>
                </td>
              </tr>
              <tr>
                <td className={ReportCSS["report-td"]}>Exam-N</td>
                <td className={ReportCSS["report-td"]}>60</td>
                <td className={ReportCSS["report-td"]}>55</td>
                <td className={ReportCSS["report-td"]}>90%</td>
                <td className={ReportCSS["report-td"]}>10%</td>
                <td className={ReportCSS["report-td"]}>
                  <Link to="/qa_instructorfeedback">
                    <button>Feedback</button>{" "}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default QA_REPORT;
