import React from "react";
import profileCSS from "../CSS/student_profile.module.css";
import studentImage from "../FILES/student.png";

const StudentProfile = () => {
  return (
    <body className={profileCSS["profile-body"]}>
      <header className={profileCSS["profile-header"]}>
        <h1>Teena</h1>
        <p>MS CSE Student</p>
        <p>User Id: 1002405689</p>
      </header>
      <div className={profileCSS["std-profile"]}>
        <img
          src={studentImage}
          alt="Your Profile Image"
          className={profileCSS["profile-img"]}
        />
        <div>
          <h2>About Me</h2>
          <h2>Overall Progress</h2>
          <div className={profileCSS["progress-chart"]}>
            <div className={profileCSS["chart-label"]}>75%</div>
            <div className={profileCSS["progress-bar"]}></div>
            <p>
              No.of exams taken: 5 out of 7<br />
              Highest Score: 100
              <br />
              Lowest Score: 65
            </p>
          </div>

          <h2>Contact Information</h2>
          <p>
            Email: sxk2500@mavs.uta.edu
            <br />
            Phone: +1 8136132457
            <br />
            Address: 2001 S Cooper St
            <br />
            Arlington, 76010, Texas
          </p>
          <button className={profileCSS["button"]}>Edit</button>

          <h2>Personal Information</h2>
          <p>
            Gender: Female
            <br />
            SSN: XXXX879
            <br />
            Country: Mexico
          </p>
          <button className={profileCSS["button"]}>Edit</button>
        </div>
      </div>
    </body>
  );
};

export default StudentProfile;
