import React from "react";
import courseCSS from "../CSS/qa_courses.module.css";
import { Link } from "react-router-dom";

function QA_COURSES() {
  return (
    <div className={courseCSS["course-body"]}>
      <div className={courseCSS["navbar"]}>
        <span className={courseCSS["navbar-title"]}>COURSES</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={courseCSS["qa-container"]}>
        <h1>Courses and Instructors</h1>
        <table className={courseCSS["qa-table"]}>
          <thead>
            <tr>
              <th className={courseCSS["qa-th"]}>Course</th>
              <th className={courseCSS["qa-th"]}>Instructor</th>
              <th className={courseCSS["qa-th"]}>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={courseCSS["qa-td"]}>
                CSE 5355 Web Data Management
              </td>
              <td className={courseCSS["qa-td"]}>John Doe</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>
                CSE 5360 Artificial Intelligence
              </td>
              <td className={courseCSS["qa-td"]}>Jane Smith</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>
                CSE 5322 S/W design Patterns
              </td>
              <td className={courseCSS["qa-td"]}>Michael Johnson</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>CSE 5321 S/W Testing</td>
              <td className={courseCSS["qa-td"]}>Jaime Escalante</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>
                CSE 5692 Distributed Systems
              </td>
              <td className={courseCSS["qa-td"]}>Michelle Rhee</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>CSE 6112 Machine Learning</td>
              <td className={courseCSS["qa-td"]}>Eleanor Roosevelt</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>CSE 6543 S/W Engineering</td>
              <td className={courseCSS["qa-td"]}>Maria Montessori </td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>CSE 6782 Computer Vision</td>
              <td className={courseCSS["qa-td"]}>Kane Richardson</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>CSE 5998 S/W Engineering-2</td>
              <td className={courseCSS["qa-td"]}>Michael Smith</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td className={courseCSS["qa-td"]}>
                CSE 5101 Design & Analysis Of Patterns
              </td>
              <td className={courseCSS["qa-td"]}>John Dewey</td>
              <td className={courseCSS["qa-td"]}>
                <Link to="/qa_report">
                  <button className={courseCSS["report-button"]}>Report</button>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default QA_COURSES;
