import React from "react";
import syllabusCSS from "../CSS/qa_syllabus.module.css";
import { Link } from "react-router-dom";

function QA_SYLLABUS() {
  return (
    <div className={syllabusCSS["syll-body"]}>
      <div className={syllabusCSS["navbar"]}>
        <span className={syllabusCSS["navbar-title"]}>POLICIES</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <br />
      <br />
      <table
        className={syllabusCSS["qa-table"]}
        border="2"
        style={{ width: "50%" }}
      >
        <th className={syllabusCSS["qa-th"]}>Courses</th>
        <th className={syllabusCSS["qa-th"]}>Syllabus</th>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 5355 Web Data Management</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>
            CSE 5360 Artificial Intelligence
          </td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 5322 S/W design Patterns</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 5321 S/W Testing</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 5692 Distributed Systems</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 6112 Machine Learning</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 6543 S/W Engineering</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 6782 Computer Vision</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>CSE 5998 S/W Engineering-2</td>
          <td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
        <tr>
          <td className={syllabusCSS["qa-td"]}>
            CSE 5101 Design & Analysis Of Patterns
          </td>
          <td td className={syllabusCSS["qa-td"]}>
            <Link to="../FILES/syllabus.pdf" download="Syllabus">
              Download
            </Link>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default QA_SYLLABUS;
