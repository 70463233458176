import React from "react";
import adminCSS from "../CSS/admin_admin.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const AdminPage = () => {
  return (
    <div className={adminCSS["admin-body"]}>
      <div className={adminCSS["navbar"]}>
        <span className={adminCSS["navbar-title"]}>Admin Home Page</span>
        <Link to="/login">Sign up </Link>
        <Link to="/admin_notifications">Notifications</Link>
        <Link to="/admin_message">Message</Link>
      </div>

      <div className={adminCSS["admin-tile"]}>
        <h2 className={adminCSS["admin-h2"]}>Students</h2>
        <div>
          <button>
            <Link to="/admin_studentsinfo">View Details</Link>
          </button>
        </div>
      </div>
      <div className={adminCSS["admin-tile"]}>
        <h2 className={adminCSS["admin-h2"]}>Instructors</h2>
        <div>
          <button>
            <Link to="/admin_instructorsinfo">View Details</Link>
          </button>
        </div>
      </div>
      <div className={adminCSS["admin-tile"]}>
        <h2 className={adminCSS["admin-h2"]}>Coordinators</h2>
        <div>
          <button>
            <Link to="/admin_coordinatorsinfo">View Details</Link>
          </button>
        </div>
      </div>
      <div className={adminCSS["admin-tile"]}>
        <h2 className={adminCSS["admin-h2"]}>QA-Officers</h2>
        <div>
          <button>
            <Link to="/admin_qaofficersinfo">View Details</Link>
          </button>
        </div>
      </div>

      <h1 className={adminCSS["admin-h1"]}>Add/Remove user's Access</h1>
      <table className={adminCSS["user-access-table"]}>
        <tr>
          <th className={adminCSS["user-access-table-th"]}>User ID:</th>
          <td className={adminCSS["user-access-table-td"]}>
            <input type="text" id="user_id" name="user_id" />
          </td>
        </tr>
        <tr>
          <th className={adminCSS["user-access-table-th"]}>Email:</th>
          <td className={adminCSS["user-access-table-td"]}>
            <input type="email" id="email" name="email" required />
          </td>
        </tr>
        <tr>
          <th className={adminCSS["user-access-table-th"]}>User is a:</th>
          <td className={adminCSS["user-access-table-td"]}>
            <select id="role" name="role">
              <option value="student">Student</option>
              <option value="faculty">Faculty</option>
              <option value="student">QA Officer</option>
              <option value="faculty">Program Coordinator</option>
            </select>
          </td>
        </tr>
        <tr>
          <td className={adminCSS["user-access-table-td"]} colSpan="2">
            <button type="submit">Give Access</button>
            <button type="submit">Remove Access</button>
          </td>
        </tr>
      </table>

      <Link to="/admin_monitor" className={adminCSS["button1"]}>
        Monitor Activities
      </Link>
      <br />

      <div className={adminCSS["chat-container"]}>
        <div className={adminCSS["chat-header"]}>Help Desk Chat Bot</div>
        <div className={adminCSS["chat-messages"]}>Hi, How may I help you?</div>
        <div className={adminCSS["chat-input"]}>
          <input type="text" />
          <button className={adminCSS["send-button"]}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
