import React from "react";
import QA_DashboardCSS from "../CSS/qa_dashboard.module.css";
import { Link } from "react-router-dom";


function QA_DASHBOARD() {
  return (
    <div className={QA_DashboardCSS["dash-body"]}>
      <div className={QA_DashboardCSS["navbar"]}>
        <span className={QA_DashboardCSS["navbar-title"]}>QA Officer Page</span>
        <Link to="/login">SignOut</Link>
        <Link to="/qa_profile">My Profile</Link>
        <Link to="/qa_message">Message</Link>
        <Link to="/qa_notifications">Notifications</Link>
      </div>
      <br />
      <br />
      <br />
      <div className={QA_DashboardCSS["qa-tile"]}>
        <h3>My Program</h3>
        <div className={QA_DashboardCSS["qa-dropdown"]}>
          <div className={QA_DashboardCSS["qa-dropdown"]}>
            <Link to="/qa_courses">
              <button className={QA_DashboardCSS["qa-dropbtn"]}>
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={QA_DashboardCSS["qa-tile"]}>
        <h3>Program Policies</h3>
        <div className={QA_DashboardCSS["qa-dropdown"]}>
          <div className={QA_DashboardCSS["qa-dropdown"]}>
            <Link to="/qa_policies">
              <button className={QA_DashboardCSS["qa-dropbtn"]}>
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={QA_DashboardCSS["qa-tile"]}>
        <h3>Feedback Instructor</h3>
        <div className={QA_DashboardCSS["qa-dropdown"]}>
          <div className={QA_DashboardCSS["qa-dropdown"]}>
            <Link to="/qa_instructorfeedback">
              <button className={QA_DashboardCSS["qa-dropbtn"]}>
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={QA_DashboardCSS["qa-tile"]}>
        <h3>All Courses</h3>
        <div className={QA_DashboardCSS["qa-dropdown"]}>
          <div className={QA_DashboardCSS["qa-dropdown"]}>
            <Link to="/qa_Syllabus">
              <button className={QA_DashboardCSS["qa-dropbtn"]}>
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={QA_DashboardCSS["qa-tile"]}>
        <h3>Chat with Instructor</h3>
        <div className={QA_DashboardCSS["qa-dropdown"]}>
          <div className={QA_DashboardCSS["qa-dropdown"]}>
            <Link to="/qa_chat">
              <button className={QA_DashboardCSS["qa-dropbtn"]}>
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QA_DASHBOARD;
