import React from "react";
import infoCSS from "../CSS/admin_student1.module.css";
import penImage from "../FILES/pen.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentInformation = () => {
  return (
    <div className={infoCSS["info1-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>Student Information</span>
        <Link to="/admin_studentsinfo">All student's Info</Link>
      </div>
      <br />
      <Link to="#">
        <button className={infoCSS["info1-button"]}>Edit</button>
      </Link>
      <br />
      <table className={infoCSS["student-info"]}>
        <br />
        <div>
          <img src={penImage} alt="Edit" />
          <p>
            <span className={infoCSS["info1-label"]}>Name:</span> John Doe
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Student ID:</span> 12345
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Major:</span> Computer
            Science
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Access:</span> Not
            Allocated
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Date of Birth:</span>{" "}
            January 1, 2000
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Address:</span> 123 Main
            Street, City, State
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Email:</span>{" "}
            john.doe@example.com
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Phone:</span> (555)
            123-4567
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>
              College Joining Date:
            </span>{" "}
            September 1, 2020
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Total Credit:</span> 39
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Completed Credit:</span> 21
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>On-Campus Job:</span>{" "}
            Student Assistant
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>GPA:</span> 3.78
          </p>
        </div>
      </table>
    </div>
  );
};

export default StudentInformation;
