import React from "react";
import dashboardCSS from "../CSS/pg_dashboard.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const Dashboard = () => {
  return (
    <div className={`${dashboardCSS["dashb-body"]}`}>
      <div className={`${dashboardCSS["navbar"]}`}>
        <span className={`${dashboardCSS["navbar-title"]}`}>
          Program Coordinator Page
        </span>
        <Link to="/login" className={dashboardCSS["navbar-a"]}>
  Sign Out
</Link>

<Link to="/pg_profile" className={dashboardCSS["navbar-a"]}>
  My Profile
</Link>

<Link to="/pg_message" className={dashboardCSS["navbar-a"]}>
  Message
</Link>
      </div>

      <div className={`${dashboardCSS["dashboard"]}`}>
        <div
          className={`${dashboardCSS["tile"]} ${dashboardCSS["performance-tile"]}`}
        >
          <h2 className={`${dashboardCSS["tileh2"]}`}>Student Performance</h2>
          <p>Monitor student performance data here.</p>
          <Link to="/pg_performance" className={dashboardCSS["click-button"]}>
            Click Here
          </Link>
        </div>
        <div
          className={`${dashboardCSS["tile"]} ${dashboardCSS["performance-tile"]}`}
        >
          <h2>Student Concerns</h2>
          <p>See all the queries from students here</p>
          <Link to="/pg_studentconcern" className={dashboardCSS["click-button"]}>
            Click Here
          </Link>
        </div>

        <div
          className={`${dashboardCSS["tile"]} ${dashboardCSS["notification-tile"]}`}
        >
          <h2>Notifications</h2>
          <p>
            View notifications for pending tasks, inquiries, or urgent matters.
          </p>
          <Link to="/pg_notifications" className={dashboardCSS["click-button"]}>
            Click Here
          </Link>
        </div>

        <div className={`${dashboardCSS["tile"]} ${dashboardCSS["chat-tile"]}`}>
          <h2>Chat</h2>
          <p>Access communication tools and chat with stakeholders.</p>
          <Link to="/pg_chat" className={dashboardCSS["click-button"]}>
            Click Here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
