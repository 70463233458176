import React from "react";
import infoCSS from "../CSS/admin_student1.module.css";
import penImage from "../FILES/pen.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentInformation2 = () => {
  return (
    <div className={infoCSS["info1-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>Student Information</span>
        <Link to="/admin_studentsinfo">All student's Info</Link>
      </div>
      <br />
      <Link to="#">
        <button className={infoCSS["info1-button"]}>Edit</button>
      </Link>
      <br />
      <table className={infoCSS["student-info"]}>
        <br />
        <div>
          <img src={penImage} alt="Edit" />
          <p>
            <span className={infoCSS["info1-label"]}>Name:</span> Jane Smith
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Student ID:</span> 54321
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Major:</span> Computer
            Science
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Access:</span> Allocated
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Date of Birth:</span>{" "}
            February 15, 1999
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Address:</span> 456 Elm
            Street, Springfield, IL
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Email:</span>
            jane.smith@example.com
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Phone:</span> (555)
            987-6543
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>
              College Joining Date:
            </span>
            August 28, 2019
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Total Credit:</span> 45
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Completed Credit:</span> 33
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>On-Campus Job:</span>{" "}
            Teaching Assistant
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>GPA:</span> 3.93
          </p>
        </div>
      </table>
    </div>
  );
};

export default StudentInformation2;
