import React from "react";
import searchCSS from "../CSS/student_course_search.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentSearchCourse = () => {
  return (
    <body className={searchCSS["search-body"]}>
      <div className={searchCSS["navbar"]}>
        <span className={searchCSS["navbar-title"]}>Search Course</span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <h1>Search Courses</h1>
      <form action="../HTML/search-results.html" method="GET">
        <input type="text" name="query" placeholder="Search by Course id..." />
        <button type="submit">Search</button>
      </form>
    </body>
  );
};

export default StudentSearchCourse;
