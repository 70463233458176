import React from "react";
import messageCSS from "../CSS/pg_message.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const PGMessage = () => {
  return (
    <div className={`${messageCSS["message-body"]}`}>
      <div className={`${messageCSS["navbar"]}`}>
        <span className={`${messageCSS["navbar-title"]}`}>MESSAGE</span>
        <Link to="/pg_dashboard">Go to Dashboard</Link>
      </div>
      <div className={messageCSS["message-container"]}>
        <h2>Message</h2>
        <form action="submit_feedback.php" method="POST">
          <p>Send Message to:</p>
          <input type="radio" id="Peer" name="message" value="Peer" />
          <label htmlFor="peer">Student</label>
          <br />
          <input
            type="radio"
            id="Instructor"
            name="message"
            value="Instructor"
          />
          <label htmlFor="instructor">Instructor</label>
          <br />
          <input type="radio" id="qa" name="message" value="qa" />
          <label htmlFor="qa">Quality Assurance Officer</label>
          <br />
          <input type="radio" id="admin" name="message" value="admin" />
          <label htmlFor="admin">Admin</label>
          <br />
          <p>Email:</p>
          <textarea
            id="name"
            name="name"
            rows="1"
            cols="15"
            required
          ></textarea>
          <p>Message:</p>
          <textarea
            id="feedback"
            name="feedback"
            rows="4"
            cols="20"
            required
          ></textarea>
          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
};

export default PGMessage;
