import React from "react";
import notificationsCSS from "../CSS/qa_notifications.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const QA_Notifications = () => {
  return (
    <div>
      <div className={notificationsCSS["navbar"]}>
        <span className={notificationsCSS["navbar-title"]}>Notifications</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={notificationsCSS["notifications-container"]}>
        <main>
          <section className={notificationsCSS["notification"]}>
            <div className={notificationsCSS["notification-info"]}>
              <h2>Program Updates</h2>
              <p>New course has been added by Instructor!</p>
            </div>
          </section>

          <section className={notificationsCSS["notification"]}>
            <div className={notificationsCSS["notification-info"]}>
              <h2>Student Concerns</h2>
              <p>Student 101 raised a request</p>
            </div>
          </section>
          <section className={notificationsCSS["notification"]}>
            <div className={notificationsCSS["notification-info"]}>
              <h2>Messages</h2>
              <p>New Message from Admin!</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default QA_Notifications;
