import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PGMessage from './JSX/pg_message.jsx'
import Dashboard from './JSX/pg_dashboard.jsx';
import Homepage from './JSX/admin_homepage.jsx';
import AdminAboutUs from './JSX/admin_aboutus.jsx';
import AdminServices from './JSX/admin_services.jsx';
import UniversityLogin from './JSX/admin_login.jsx';
import ContactUs from './JSX/admin_contactus.jsx';
import Notifications from './JSX/pg_notifications.jsx';
import ProfilePage from './JSX/pg_profile.jsx';
import StudentConcerns from './JSX/pg_studentconcerns.jsx';
import PerformanceDashboard from './JSX/pg_performance.jsx';
import ChatPage from './JSX/pg_chat.jsx';
import QA_ProfilePage from './JSX/qa_profile.jsx';
import QA_REPORT from './JSX/qa_report.jsx';
import QA_Notifications from './JSX/qa_notifications.jsx';
import QA_DASHBOARD from './JSX/qa_dashboard.jsx';
import QA_Message from './JSX/qa_message.jsx';
import QA_COURSES from './JSX/qa_courses.jsx';
import QA_INSTRUCTORFEEDBACK from './JSX/qa_instructorfeedback.jsx';
import QA_POLICIES from './JSX/qa_policies.jsx';
import QA_ChatPage from './JSX/qa_chat.jsx';
import QA_SYLLABUS from './JSX/qa_syllabus.jsx';
import ForgetPassword from './JSX/admin_forgetpassword.jsx';
import UniversitySignUp from './JSX/admin_signup.jsx';
import StudentDashboard from './JSX/student_dashboard.jsx';
import S_Notifications from './JSX/student_notifications.jsx';
import S_Message from './JSX/student_message.jsx';
import Std_Courses from './JSX/student_courses.jsx';
import StudentFeedback from './JSX/student_feedback.jsx';
import StudentExamSchedule from './JSX/student_examschedule.jsx';
import StudentCourseMaterial from './JSX/student_coursematerial.jsx';
import StudentProfile from './JSX/student_profile.jsx';
import StudentSearchCourse from './JSX/student_course_search.jsx';
import AdminPage from './JSX/admin_admin.jsx';
import StudentInfo from './JSX/admin_studentsinfo.jsx';
import StudentInformation from './JSX/admin_student1.jsx';
import StudentInformation2 from './JSX/admin_student2.jsx';
import AdminMessage from './JSX/admin_message.jsx';
import Admin_Notifications from './JSX/admin_notifications.jsx';
import ActivityPage from './JSX/admin_monitor.jsx';
import InstructorInfo from './JSX/admin_instructorsinfo.jsx';
import InstructorInformation from './JSX/admin_instructor1.jsx';
import InstructorInformation2 from './JSX/admin_instructor2.jsx';
import CoordinatorInfo from './JSX/admin_coordinatorsinfo.jsx';
import QAOfficerInfo from './JSX/admin_qaofficersinfo.jsx';
import CoordinatorInformation from './JSX/admin_coordinator1.jsx';
import CoordinatorInformation2 from './JSX/admin_coordinator2.jsx';
import QAOfficerInformation from './JSX/admin_qaofficer1.jsx';
import QAOfficerInformation2 from './JSX/admin_qaofficer2.jsx';
import InstructorDashboard from './JSX/instructor_dashboard.jsx';
import InstMessage from './JSX/instructor_message.jsx';
import InstNotifications from './JSX/instructor_notifications.jsx';
import Std_Performance from './JSX/instructor_student_performance.jsx';
import InstructorFeedback from './JSX/instructor_feedback.jsx';
import InstructorProfile from './JSX/instructor_profile.jsx';
import InstructorAnnouncement from './JSX/instructor_announcement.jsx';
import InstructorCreateExam from './JSX/instructor_create_exam.jsx';
import InstructorCourseObjective from './JSX/instructor_course_objective.jsx';
import InstructorGradeExam from './JSX/instructor_grade_exam.jsx';
const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/pg_dashboard" element={<Dashboard/>} />
        <Route path="/pg_message" element={<PGMessage/>} />
        <Route path="/" element={<Homepage/>} />
        <Route path="/aboutus" element={<AdminAboutUs/>} />
        <Route path="/services" element={<AdminServices/>} />
        <Route path="/login" element={<UniversityLogin/>} />
        <Route path="/contactus" element={<ContactUs/>} />        
        <Route path="/pg_notifications" element={<Notifications/>} />
        <Route path="/pg_profile" element={<ProfilePage/>} />
        <Route path="/pg_studentconcern" element={<StudentConcerns/>} />
        <Route path="/pg_performance" element={<PerformanceDashboard/>} />
        <Route path="/pg_chat" element={<ChatPage/>} />
        <Route path="/qa_profile" element={<QA_ProfilePage/>} />
        <Route path="/qa_report" element={<QA_REPORT/>} />
        <Route path="/qa_notifications" element={<QA_Notifications/>} />
        <Route path="/qa_dashboard" element={<QA_DASHBOARD/>} />
        <Route path="/qa_message" element={<QA_Message/>} />
        <Route path="/qa_courses" element={<QA_COURSES/>} />
        <Route path="/qa_instructorfeedback" element={<QA_INSTRUCTORFEEDBACK/>} />
        <Route path="/qa_policies" element={<QA_POLICIES/>} />
        <Route path="/qa_chat" element={<QA_ChatPage/>} />
        <Route path="/qa_syllabus" element={<QA_SYLLABUS/>} />
        <Route path="/forgetpassword" element={<ForgetPassword/>} />
        <Route path="/signup" element={<UniversitySignUp/>} />
        <Route path="/student_dashboard" element={<StudentDashboard/>} />
        <Route path="/student_notifications" element={<S_Notifications/>} />
        <Route path="/student_message" element={<S_Message/>} />
        <Route path="/student_courses" element={<Std_Courses/>} />
        <Route path="/student_feedback" element={<StudentFeedback/>} />
        <Route path="/student_examschedule" element={<StudentExamSchedule/>} />
        <Route path="/student_coursematerial" element={<StudentCourseMaterial/>} />
        <Route path="/student_profile" element={<StudentProfile/>} />
        <Route path="/student_course_search" element={<StudentSearchCourse/>} />
        <Route path="/admin_admin" element={<AdminPage/>} />
        <Route path="/admin_studentsinfo" element={<StudentInfo/>} />
        <Route path="/admin_student1" element={<StudentInformation/>} />
        <Route path="/admin_student2" element={<StudentInformation2/>} />
        <Route path="/admin_message" element={<AdminMessage/>} />
        <Route path="/admin_notifications" element={<Admin_Notifications/>} />
        <Route path="/admin_monitor" element={<ActivityPage/>} />
        <Route path="/admin_instructorsinfo" element={<InstructorInfo/>} />
        <Route path="/admin_instructor1" element={<InstructorInformation/>} />
        <Route path="/admin_instructor2" element={<InstructorInformation2/>} />
        <Route path="/admin_coordinatorsinfo" element={<CoordinatorInfo/>} />
        <Route path="/admin_coordinator1" element={<CoordinatorInformation/>} />
        <Route path="/admin_coordinator2" element={<CoordinatorInformation2/>} />
        <Route path="/admin_qaofficersinfo" element={<QAOfficerInfo/>} />
        <Route path="/admin_qaofficer1" element={<QAOfficerInformation/>} />
        <Route path="/admin_qaofficer2" element={<QAOfficerInformation2/>} />
        <Route path="/instructor_dashboard" element={<InstructorDashboard/>} />
        <Route path="/instructor_message" element={<InstMessage/>} />
        <Route path="/instructor_notifications" element={<InstNotifications/>} />
        <Route path="/instructor_student_performance" element={<Std_Performance/>} />
        <Route path="/instructor_feedback" element={<InstructorFeedback/>} />
        <Route path="/instructor_profile" element={<InstructorProfile/>} />
        <Route path="/instructor_announcement" element={<InstructorAnnouncement/>} />
        <Route path="/instructor_create_exam" element={<InstructorCreateExam/>} />
        <Route path="/instructor_course_objective" element={<InstructorCourseObjective/>} />
        <Route path="/instructor_grade_exam" element={<InstructorGradeExam/>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
