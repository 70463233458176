import React from "react";
import infoCSS from "../CSS/admin_studentsinfo.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentInfo = () => {
  return (
    <div className={infoCSS["info-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>
          Welcome to the Student's Information Page
        </span>
        <Link to="/admin_admin">Dashboard</Link>
      </div>
      <br />
      <br />

      <main>
        <section>
          <h2 className={infoCSS["info-h2"]}>Student Details</h2>
          <table className={infoCSS["studentinfotable"]}>
            <thead>
              <tr>
                <th className={infoCSS["studentinfotable-th"]}>Name</th>
                <th className={infoCSS["studentinfotable-th"]}>Email</th>
                <th className={infoCSS["studentinfotable-th"]}>Student ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={infoCSS["studentinfotable-td"]}>
                  <Link to="/admin_student1"
                    title="Total credits: 39, Completed Credit: 21, GPA: 3.78"
                  >
                    John Doe
                  </Link>
                </td>
                <td className={infoCSS["studentinfotable-td"]}>
                  john.doe@example.com
                </td>
                <td className={infoCSS["studentinfotable-td"]}>12345</td>
              </tr>
              <tr>
                <td className={infoCSS["studentinfotable-td"]}>
                  <Link to="/admin_student2"
                    title="Total credits: 45, Completed Credit: 33, GPA: 3.3"
                  >
                    Jane Smith
                  </Link>
                </td>
                <td className={infoCSS["studentinfotable-td"]}>
                  jane.smith@example.com
                </td>
                <td className={infoCSS["studentinfotable-td"]}>54321</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default StudentInfo;
