import React from "react";
import dashboardCSS from "../CSS/instructor_dashboard.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const InstructorDashboard = () => {
  return (
    <div className={dashboardCSS["instr-body"]}>
      <div className={dashboardCSS["navbar"]}>
        <span className={dashboardCSS["navbar-title"]}>
          Instructor Dashboard
        </span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_message">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <br />
      <div className={dashboardCSS["instr-tile"]}>
        <h1>My Courses</h1>
        <div className={dashboardCSS["dropdown"]}>
          <button className={dashboardCSS["dropbtn"]}>View My Courses</button>
          <div className={dashboardCSS["dropdown-content"]}>
            <Link to="/instructor_course_objective">
              CSE 5355: Web Data Management
            </Link>
            <Link to="/#">CSE 5392: Distributed systems</Link>
            <Link to="/#">CSE 6543: Adv Software Engineering</Link>
          </div>
        </div>
      </div>
      <div className={dashboardCSS["instr-tile"]}>
        <h1>Create Exam</h1>
        <div className={dashboardCSS["dropdown"]}>
          <button className={dashboardCSS["dropbtn"]}>View My Courses</button>
          <div className={dashboardCSS["dropdown-content"]}>
            <Link to="/instructor_create_exam">CSE 5355: Web Data Management</Link>
            <Link to="/#">CSE 5392: Distributed systems</Link>
            <Link to="/#">CSE 6543: Adv Software Engineering</Link>
          </div>
        </div>
      </div>
      <div className={dashboardCSS["instr-tile"]}>
        <h1>Grade Exam</h1>
        <div className={dashboardCSS["dropdown"]}>
          <button className={dashboardCSS["dropbtn"]}>Select Course</button>
          <div className={dashboardCSS["dropdown-content"]}>
            <Link to="/instructor_grade_exam">CSE 5355: Web Data Management</Link>
            <Link to="/#">CSE 5392: Distributed systems</Link>
            <Link to="#">CSE 6543: Adv Software Engineering</Link>
          </div>
        </div>
      </div>
      <div className={dashboardCSS["instr-tile"]}>
        <h1>Student Progress</h1>
        <div className={dashboardCSS["dropdown"]}>
          <button className={dashboardCSS["dropbtn"]}>Select Course</button>
          <div className={dashboardCSS["dropdown-content"]}>
            <Link to="/instructor_student_performance">
              CSE 5355: Web Data Management
            </Link>
            <Link to="#">CSE 5392: Distributed systems</Link>
            <Link to="#">CSE 6543: Adv Software Engineering</Link>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className={dashboardCSS["chat-container"]}>
        <div className={dashboardCSS["chat-header"]}>Help Desk Chat Bot</div>
        <div className={dashboardCSS["chat-messages"]}></div>
        <div className={dashboardCSS["chat-input"]}>
          <input type="text" placeholder="Type your message..." />
          <button className={dashboardCSS["send-button"]}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default InstructorDashboard;
