import React from "react";
import signupCSS from "../CSS/admin_signup.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const UniversitySignUp = () => {
  return (
    <div className={signupCSS["signup-body"]}>
      <div className={signupCSS["signup-container"]}>
        <h1 className={signupCSS["signup-h1"]}>University Sign-Up</h1>
        <form className={signupCSS["form"]} action="/register" method="post">
          <label className={signupCSS["signup-label"]} htmlFor="full_name">
            Full Name:
          </label>
          <input
            className={signupCSS["inputField"]}
            type="text"
            id="full_name"
            name="full_name"
            required
          />

          <label className={signupCSS["signup-label"]} htmlFor="email">
            Email:
          </label>
          <input
            className={signupCSS["inputField"]}
            type="email"
            id="email"
            name="email"
            required
          />

          <label className={signupCSS["signup-label"]} htmlFor="password">
            Password:
          </label>
          <input
            className={signupCSS["inputField"]}
            type="password"
            id="password"
            name="password"
            required
          />

          <label
            className={signupCSS["signup-label"]}
            htmlFor="confirm_password"
          >
            Confirm Password:
          </label>
          <input
            className={signupCSS["inputField"]}
            type="password"
            id="confirm_password"
            name="confirm_password"
            required
          />

          <label className={signupCSS["signup-label"]} htmlFor="role">
            I am a:
          </label>
          <select className={signupCSS["selectRole"]} id="role" name="role">
            <option className={signupCSS["optionRole"]} value="student">
              Student
            </option>
            <option className={signupCSS["optionRole"]} value="faculty">
              Faculty
            </option>
            <option className={signupCSS["optionRole"]} value="qa_officer">
              QA officer
            </option>
            <option
              className={signupCSS["optionRole"]}
              value="program_coordinator"
            >
              Program Co-ordinator
            </option>
          </select>

          <div id="user_fields">
            <label className={signupCSS["signup-label"]} htmlFor="user_id">
              User ID:
            </label>
            <input
              className={signupCSS["inputField"]}
              type="text"
              id="user_id"
              name="user_id"
            />
          </div>

          <div>
            <label className={signupCSS["signup-label"]} htmlFor="department">
              Department:
            </label>
            <input
              className={signupCSS["inputField"]}
              type="text"
              id="department"
              name="department"
            />
          </div>

          <button className={signupCSS["signup-button"]} type="submit">
            Sign Up
          </button>
        </form>

        <p className={signupCSS["signup-p"]}>
          Already have an account?{" "}
          <Link to="/login" className={signupCSS["signup-a"]}>
  Log in
</Link>
        </p>
      </div>
    </div>
  );
};

export default UniversitySignUp;
