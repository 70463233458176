import React from "react";
import homepageCSS from "../CSS/admin_homepage.module.css";
import university from "../FILES/university.jpg";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary
const Homepage = () => {
  return (
    <div className={`${homepageCSS["home-body"]}`}>
      <div className={`${homepageCSS["navbar"]}`}>
        <span className={`${homepageCSS["navbar-title"]}`}>AcademiaHub</span>
        <Link to="/login">Log in</Link>
        <Link to="/services">Service Page</Link>
        <Link to="/aboutus">About Us</Link>
        <Link to="/contactus">Contact Us</Link>
        <Link to="https://kxs9489.uta.cloud/">Blog</Link>
      </div>

      <div>
        <h2 className={`${homepageCSS["homepage-h2"]}`}>
          {" "}
          Msc Academic Program
        </h2>
        <img
          src={university}
          alt="University"
          className={`${homepageCSS["homepageimg"]}`}
        />
        <p>
          Welcome to Academia Hub, a renowned institution dedicated to the
          pursuit of knowledge and academic excellence. Since our establishment
          in [year of establishment], we have been a beacon of learning,
          research, and innovation. Situated in the vibrant city of [city or
          region], Academia Hub is at the forefront of higher education,
          providing students with an exceptional platform to explore their
          passions, develop critical thinking skills, and prepare for a
          successful future.
          <br />
          <br />
          At Academia Hub, our unwavering commitment is to deliver unparalleled
          academic excellence. Our institution offers a diverse range of
          undergraduate and graduate programs across various disciplines, all
          designed to challenge and inspire. Our esteemed faculty, composed of
          distinguished scholars and experts in their fields, are dedicated to
          guiding students towards intellectual growth and success. Whether
          you're pursuing a degree in the arts, sciences, engineering, business,
          or any other field, Academia Hub equips you with the knowledge and
          skills necessary to excel in your chosen path.
          <br />
          <br />
          Academia Hub offers more than just academic rigor; we provide a
          vibrant and inclusive campus life. Students have the opportunity to
          engage in a multitude of extracurricular activities, clubs, and
          organizations that cater to diverse interests and passions. Our modern
          facilities, well-stocked libraries, and cutting-edge research centers
          create an environment that encourages exploration and discovery.
          Moreover, our institution takes great pride in fostering a strong
          sense of community and social responsibility. Join us at Academia Hub,
          where you'll not only receive a world-class education but also forge
          lasting connections and develop into a well-rounded individual.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default Homepage;
