import React from "react";
import courseCSS from "../CSS/student_coursematerial.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentCourseMaterial = () => {
  return (
    <body className={courseCSS["coursem-body"]}>
      <div className={courseCSS["navbar"]}>
        <span className={courseCSS["navbar-title"]}>Course Materials</span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <div class={courseCSS["course-container"]}>
        <h1 class={courseCSS["course-h1"]}>Web Data Management</h1>
        <p>Course Materials</p>

        <h2>Modules</h2>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/3rdEd_Chapter 1.pptx" download>
            Module 1: Introduction to Web Development
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/3rdEd_Chapter 2.pptx" download>
            Module 2: How the Web Works
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/3rdEd_Chapter 3.pptx" download>
            Module 3: HTML Introduction
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/3rdEd_Chapter 5.pptx" download>
            Module 4: HTML 2: Tables and Forms
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module1.pdf" download>
            Module 5: CSS Layout
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module2.pdf" download>
            Module 6: Javascript Fundamentals
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module1.pdf" download>
            Module 7: Using JvaScript
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module2.pdf" download>
            Module 8: React
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module1.pdf" download>
            Module 9: Server Side Development: PHP
          </Link>
        </div>
        <div class={courseCSS["course-module"]}>
          <Link to="../FILES/module2.pdf" download>
            Module 10: Server-Side Node.js
          </Link>
        </div>

        <p>
          For more information, contact:{" "}
          <Link to="mailto:instructor@email.com">Stella Breeze</Link>
        </p>
      </div>
    </body>
  );
};

export default StudentCourseMaterial;
