import React from "react";
import styles from "../CSS/instructor_announcement.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


function InstructorAnnouncement() {
  return (
    <div className={styles["ann-body"]}>
      <div className={styles["navbar"]}>
        <span className={styles["navbar-title"]}>Announcements</span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_message">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <div className={styles["ann-container"]}>
        <h2>Announcement</h2>
        <form action="submit_feedback.php" method="POST">
          <p>Send Announcement to:</p>
          <select id="role" name="role">
            <option value="wdm">CSE 5355 Web Data Management</option>
            <option value="cv">CSE 5392 Distributed Systems</option>
            <option value="adv se">CSE 6543 Adv Software Engineering</option>
          </select>
          <p>Announcement:</p>
          <textarea
            id="feedback"
            name="feedback"
            rows="4"
            cols="20"
            required
          ></textarea>
          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
}

export default InstructorAnnouncement;
