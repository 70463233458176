import React from "react";
import policyCSS from "../CSS/qa_policies.module.css";
import { Link } from "react-router-dom";

function QA_POLICIES() {
  return (
    <div className={policyCSS["policy-body"]}>
      <div className={policyCSS["navbar"]}>
        <span className={policyCSS["navbar-title"]}>POLICIES</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={policyCSS["qa-container"]}>
        <h1>List of Policies</h1>
        <ul className={policyCSS["policy-list"]}>
          <li className={policyCSS["policy-list-li"]}>
            Admissions Policies
            <div className={policyCSS["policy-list-li-actions"]}>
              <button className={policyCSS["qa-button"]}>Edit</button>
              <button className={policyCSS["qa-button"]}>Delete</button>
            </div>
          </li>
          <li className={policyCSS["policy-list-li"]}>
            Integrity Policies
            <div className={policyCSS["policy-list-li-actions"]}>
              <button className={policyCSS["qa-button"]}>Edit</button>
              <button className={policyCSS["qa-button"]}>Delete</button>
            </div>
          </li>
          <li className={policyCSS["policy-list-li"]}>
            Grading and Evaluation Policies
            <div className={policyCSS["policy-list-li-actions"]}>
              <button className={policyCSS["qa-button"]}>Edit</button>
              <button className={policyCSS["qa-button"]}>Delete</button>
            </div>
          </li>
          <li className={policyCSS["policy-list-li"]}>
            Student Rights and Responsibilities
            <div className={policyCSS["policy-list-li-actions"]}>
              <button className={policyCSS["qa-button"]}>Edit</button>
              <button className={policyCSS["qa-button"]}>Delete</button>
            </div>
          </li>
        </ul>
        <button className={policyCSS["create-button"]}>
          Create New Policy
        </button>
      </div>
    </div>
  );
}

export default QA_POLICIES;
