import React from "react";
import pwdCSS from "../CSS/admin_forgetpassword.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const ForgetPassword = () => {
  return (
    <div className={pwdCSS["pwd-body"]}>
      <div className={pwdCSS["pwd-container"]}>
        <h1 className={pwdCSS["pwd-h1"]}>Forget Password ?</h1>
        <form
          className={pwdCSS["pwd-form"]}
          action="/forgot-password"
          method="post"
        >
          <label className={pwdCSS["pwd-label"]} htmlFor="username">
            User Name:
          </label>
          <input
            className={pwdCSS["pwd-inputField"]}
            type="text"
            id="username"
            name="username"
            required
          />
          <label className={pwdCSS["pwd-label"]} htmlFor="email">
            Email:
          </label>
          <input
            className={pwdCSS["pwd-inputField"]}
            type="email"
            id="email"
            name="email"
            required
          />
          <h2 className={pwdCSS["pwd-h2"]}>
            The link for reset password will be sent to this email
          </h2>
          <button className={pwdCSS["pwd-button"]} type="submit">
            Submit
          </button>
        </form>
        <p className={pwdCSS["pwd-p"]}>
          Back to login Page?{" "}
          <Link to="/login" className={pwdCSS["pwd-a"]}>
  Log in
</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
