import React from "react";
import profCSS from "../CSS/instructor_profile.module.css";
import InstrutorImage from "../FILES/instructor.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const InstructorProfile = () => {
  return (
    <div className={profCSS["pro-body"]}>
      <header className={profCSS["pro-header"]}>
        <h1>Regina</h1>
        <p>Instructor</p>
        <p>User Id: 50026899</p>
      </header>
      <div className={profCSS["profile"]}>
        <img src={InstrutorImage} alt="Your Profile Image" />
        <h2>About Me</h2>
        <h2>Strength</h2>
        <p>Experience: 7 Yr 3 Months</p>
        <p>Rating: 4.5/5.0</p>
        <p>Feedback: Excellent</p>

        <h2>Contact Information</h2>

        <p>
          Email: reg9989@mavs.uta.edu
          <br />
          Phone: +1 6578797688
          <br /> Address: 9600 S Mitchel St
          <br />
          Arlington,76013,Texas
        </p>
        <button className={profCSS["button"]}>Edit</button>
        <h2>Personal Information</h2>
        <p>
          Gender: Female
          <br /> SSN: XXXX777
          <br />
          Country: US
        </p>
        <button className={profCSS["button "]}>Edit</button>
      </div>
    </div>
  );
};

export default InstructorProfile;
