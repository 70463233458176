import React from "react";
import S_DashboardCSS from "../CSS/student_dashboard.module.css"; // Import CSS using CSS modules
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const StudentDashboard = () => {
  return (
    <div className={S_DashboardCSS["std-body"]}>
      <div className={S_DashboardCSS["navbar"]}>
        <span className={S_DashboardCSS["navbar-title"]}>
          Student Home Page
        </span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <br></br>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5355 WEB DATA MANAGEMENT</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#"
              title="Web data management is a critical field in the realm of information technology and data science. It encompasses various practices and techniques for effectively storing, organizing, retrieving, and manipulating data on the web."
            >
              Course Objective
            </Link>
            <Link to="../FILES/student_coursesyllabus.docx">Syllabus</Link>
            <Link to="#"
              title="Instructor name: XYZ
                              Venue: NH 110
                              Timing: MW: 2:00PM - 3:30 PM"
            >
              Timing
            </Link>
          </div>
        </div>
      </div>

      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5360 ARTIFICIAL INTELLIGENCE</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5322 S/W DESIGN PATTERNS</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5321 SOFTWARE TESTING</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5692 DISTRIBUTED SYSTEMS</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 6112 MACHINE LEARNING</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 6543 ADV SOFTWARE ENGINEERING</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>

      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5998 SOFTWARE ENGINEERING 2</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <div className={S_DashboardCSS["tile"]}>
        <h2>CSE 5782 SOFTWARE ENGINEERING 1</h2>
        <div className={S_DashboardCSS["dropdown"]}>
          <button className={S_DashboardCSS["dropbtn"]}>View Details</button>
          <div className={S_DashboardCSS["dropdown-content"]}>
            <Link to="#">Course Objective</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Timing</Link>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <div className={S_DashboardCSS["chat-container"]}>
        <div className={S_DashboardCSS["chat-header"]}>Help Desk Chat Bot</div>
        <div className={S_DashboardCSS["chat-messages"]}></div>
        <div className={S_DashboardCSS["chat-input"]}>
          <input type="text" placeholder="Type your message..." />
          <button className={S_DashboardCSS["send-button"]}>Send</button>
        </div>
      </div>
    </div>
  );
};
export default StudentDashboard;
