import React from "react";
import contactusCSS from "../CSS/admin_contactus.module.css";
import facebook from "../FILES/facebook.png";
import insta from "../FILES/insta.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

import twitter from "../FILES/twitter.png";
const ContactUs = () => {
  return (
    <div className={contactusCSS["contactus-body"]}>
      <header className={contactusCSS["contactus-header"]}>
        <h1 className={`${contactusCSS["contactus-h1"]}`}>Contact Us</h1>
        <p>Get in touch with us. We'd love to hear from you!</p>
      </header>
      <div className={contactusCSS["contactus-container"]}>
        <div>
          <form
            action="#"
            method="post"
            className={contactusCSS["contact-form"]}
          >
            <h2 className={`${contactusCSS["contactus-h2"]}`}>Contact Form</h2>
            <label htmlFor="name">Name:</label>
            <input
              className={contactusCSS["contact-inputField"]}
              type="text"
              id="name"
              name="name"
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              className={contactusCSS["contact-inputField"]}
              type="email"
              id="email"
              name="email"
              required
            />

            <label htmlFor="message">Message:</label>
            <textarea
              className={contactusCSS["contact-inputField"]}
              id="message"
              name="message"
              rows="4"
              required
            ></textarea>

            <button className={contactusCSS["contactus-button"]}>Submit</button>
            <br />
            <br />
          </form>
          <Link to="/">
            <button className={contactusCSS["contactus-button"]}>
              Homepage
            </button>
          </Link>
        </div>
        <div className={contactusCSS["social-media"]}>
          <h1>Follow Us</h1>
          <Link to="www.facebook.com/"><img src={facebook} alt="Facebook" /></Link>

            
          <Link to="www.twitter.com/">           <img src={twitter} alt="Twitter" /></Link>
 
            <Link to="www.instagram.com/">      <img src={insta} alt="Instagram" /></Link>
      
       
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
