import React from "react";
import feedbCSS from "../CSS/instructor_feedback.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const InstructorFeedback = () => {
  return (
    <body className={feedbCSS["feedb-body"]}>
      <div className={feedbCSS["navbar"]}>
        <span className={feedbCSS["navbar-title"]}>Student Feedback</span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_messages">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <div className={feedbCSS["feedb-container"]}>
        <h2>Feedback Student</h2>
        <form action="submit_feedback.php" method="POST">
          <p>Select Rating:</p>
          <input type="radio" id="excellent" name="Rating" value="Excellent" />
          <label htmlFor="excellent">Excellent</label>
          <br />
          <input type="radio" id="Good" name="Rating" value="Good" />
          <label htmlFor="good">Good</label>
          <br />
          <input type="radio" id="bad" name="Rating" value="Bad" />
          <label htmlFor="bad">Bad</label>
          <br />

          <p>Feedback:</p>
          <textarea
            id="feedback"
            name="feedback"
            rows="4"
            cols="50"
            required
          ></textarea>

          <input type="submit" value="Submit Feedback" />
        </form>
      </div>
    </body>
  );
};

export default InstructorFeedback;
