import React from "react";
import infoCSS from "../CSS/admin_coordinator1.module.css";
import penImage from "../FILES/pen.png";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const CoordinatorInformation2 = () => {
  return (
    <div className={infoCSS["info1-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>
          Program Coordinator's Information
        </span>
        <Link to="/admin_coordinatorsinfo">All Program Coordinator's Info</Link>
      </div>
      <br />
      <Link to="#">
        <button className={infoCSS["info1-button"]}>Edit</button>
      </Link>
      <br />
      <table className={infoCSS["pc-info"]}>
        <br />
        <div>
          <img src={penImage} alt="Edit" />
          <p>
            <span className={infoCSS["info1-label"]}>Name:</span> Thaddeus
            Whitaker
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>User ID:</span> 13579
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Access:</span>Restricted
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Address:</span>101 Pine
            Street, Town, County
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Email:</span>{" "}
            thaddeus.whitaker@example.com
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Phone:</span> (555)
            321-5678
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>
              College Joining Date:
            </span>{" "}
            April 5, 2017
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Years of Experience:</span>
            10
          </p>
          <p>
            <span className={infoCSS["info1-label"]}>Ratings:</span> 7
          </p>
        </div>
      </table>
    </div>
  );
};

export default CoordinatorInformation2;
