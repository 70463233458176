import React from "react";
import objCSS from "../CSS/instructor_course_objective.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const InstructorCourseObjective = () => {
  return (
    <div className={objCSS["obj-body"]}>
      <div className={objCSS["navbar"]}>
        <span className={objCSS["navbar-title"]}>My Course Content</span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_message">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <div className={objCSS["exam-section"]}>
        <h2>Course Description</h2>
        <p>
          This course provides an in-depth study of web data management,
          focusing on the principles, technologies, and best practices for
          handling data in web-based applications. Students will learn about
          data modeling, storage, retrieval, and manipulation techniques in the
          context of web development.
        </p>
        <button className={objCSS["edit-button"]}>Edit</button>
      </div>
      <div className={objCSS["exam-section"]}>
        <h2>Course Objectives</h2>
        <p>
          Understand the fundamentals of web data management. Design and
          implement databases for web applications. Use SQL and other query
          languages for data retrieval. Implement data security and access
          control measures. Explore emerging trends and technologies in web data
          management.
        </p>
        <button className={objCSS["edit-button"]}>Edit</button>
      </div>
      <div className={objCSS["exam-section"]}>
        <h2>Course Syllabus</h2>
        <p>
          Week 1: Introduction to Web Data Management Overview of web data
          management concepts Types of data in web applications Week 2:
          Relational Database Basics Introduction to relational databases
          Structured Query Language (SQL) fundamentals Week 3: Database Design
          Entity-Relationship Diagrams (ERDs) Normalization and data modeling
          Week 4: Querying Databases SELECT statements and basic queries Joins
          and subqueries Week 5: Data Integrity and Transactions Constraints and
          triggers ACID properties of transactions Week 6: Performance
          Optimization Indexing and query optimization techniques Caching
          strategies for web applications Week 7: NoSQL Databases Introduction
          to NoSQL data models Use cases for NoSQL databases Week 8: Web APIs
          and Data Retrieval RESTful APIs and data exchange formats (JSON, XML)
          Consuming APIs in web applications Week 9: Data Security
          Authentication and authorization Security best practices for web
          databases Week 10: Data Privacy and Compliance GDPR and other data
          protection regulations Privacy considerations in web data management
          Week 11: Web Scraping and Data Extraction Techniques for extracting
          data from websites Ethical considerations and legal implications
        </p>
        <button className={objCSS["edit-button"]}>Edit</button>
      </div>
    </div>
  );
};

export default InstructorCourseObjective;
