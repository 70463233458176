import React from "react";
import infoCSS from "../CSS/admin_qaofficersinfo.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const QAOfficerInfo = () => {
  return (
    <div className={infoCSS["info-body"]}>
      <div className={infoCSS["navbar"]}>
        <span className={infoCSS["navbar-title"]}>
          Welcome to the QA Officer's Information Page
        </span>
        <Link to="/admin_admin">Dashboard</Link>
      </div>
      <br />
      <br />
      <main>
        <section>
          <h2 className={infoCSS["info-h2"]}>QA Officer's Details</h2>
          <table className={infoCSS["qainfotable"]}>
            <thead>
              <tr>
                <th className={infoCSS["qainfotable-th"]}>Name</th>
                <th className={infoCSS["qainfotable-th"]}>Email</th>
                <th className={infoCSS["qainfotable-th"]}>User ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={infoCSS["qainfotable-td"]}>
                  <Link to="/admin_qaofficer1"
                    title="Total credits: 39, Completed Credit: 21, GPA: 3.78"
                  >
                    Luis Evans
                  </Link>
                </td>
                <td className={infoCSS["qainfotable-td"]}>
                  luis.evans@example.com
                </td>
                <td className={infoCSS["qainfotable-td"]}>54321</td>
              </tr>
              <tr>
                <td className={infoCSS["qainfotable-td"]}>
                  <Link to="/admin_qaofficer2"
                    title="Total credits: 45, Completed Credit: 33, GPA: 3.3"
                  >
                    Sarah Smith
                  </Link>
                </td>
                <td className={infoCSS["qainfotable-td"]}>
                  Sarahsmith@example.com
                </td>
                <td className={infoCSS["qainfotable-td"]}>98765</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default QAOfficerInfo;
