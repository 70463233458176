import React from "react";
import feedbackCSS from "../CSS/qa_instructorfeedback.module.css";
import { Link } from "react-router-dom";


function QA_INSTRUCTORFEEDBACK() {
  return (
    <div className={feedbackCSS["feed-body"]}>
      <div className={feedbackCSS["navbar"]}>
        <span className={feedbackCSS["navbar-title"]}>FEEDBACK</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={feedbackCSS["qa-container"]}>
        <h1>Feedback for Instructor</h1>

        <div className={feedbackCSS["feedback-form"]}>
          <h2>Submit Feedback</h2>
          <form action="#" method="post">
            <label className={feedbackCSS["qa-label"]} for="name">
              Your Name:
            </label>
            <input
              className={feedbackCSS["qa-input"]}
              type="text"
              id="name"
              name="name"
              required
            />

            <label className={feedbackCSS["qa-label"]} for="email">
              Your Email:
            </label>
            <input
              className={feedbackCSS["qa-input"]}
              type="email"
              id="email"
              name="email"
              required
            />

            <label className={feedbackCSS["qa-label"]} for="feedback">
              Your Feedback:
            </label>
            <textarea
              className={feedbackCSS["qa-textarea"]}
              id="feedback"
              name="feedback"
              rows="5"
              required
            ></textarea>

            <button className={feedbackCSS["qa-button"]} type="submit">
              Submit Feedback
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default QA_INSTRUCTORFEEDBACK;
