import React from "react";
import styles from "../CSS/student_feedback.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

function StudentFeedback() {
  return (
    <body className={styles["fed-body"]}>
      <div className={styles["navbar"]}>
        <span className={styles["navbar-title"]}>
          Course/Instructor Feedback
        </span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <div className={styles["student-container"]}>
        <h2>Feedback Form</h2>
        <form action="submit_feedback.php" method="POST">
          <p>Select Feedback Type:</p>
          <input type="radio" id="course" name="feedbackType" value="Course" />
          <label htmlFor="course">Course</label>
          <br />
          <input
            type="radio"
            id="instructor"
            name="feedbackType"
            value="Instructor"
          />
          <label htmlFor="instructor">Instructor</label>
          <br />

          <p>Select Rating:</p>
          <input type="radio" id="excellent" name="Rating" value="Excellent" />
          <label htmlFor="excellent">Excellent</label>
          <br />
          <input type="radio" id="Good" name="Rating" value="Good" />
          <label htmlFor="good">Good</label>
          <br />
          <input type="radio" id="bad" name="Rating" value="Bad" />
          <label htmlFor="bad">Bad</label>
          <br />

          <p>Feedback:</p>
          <textarea
            id="feedback"
            name="feedback"
            rows="4"
            cols="50"
            required
          ></textarea>

          <input type="submit" value="Submit Feedback" />
        </form>
      </div>
    </body>
  );
}

export default StudentFeedback;
