import React from "react";
import chatCSS from "../CSS/pg_chat.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const ChatPage = () => {
  return (
    <div className={chatCSS["chat-body"]}>
      <div className={chatCSS["navbar"]}>
        <span className={chatCSS["navbar-title"]}>CHAT</span>
        <Link to="/pg_dashboard">Go to Dashboard</Link>
      </div>
      <div className={chatCSS["chat-container"]}>
        <div className={chatCSS["chat-box"]}>
          <div className={chatCSS["chat-header"]}>
            Program Coordinator - Instructor Chat
          </div>
          <div
            className={`${chatCSS["chat-messages"]} ${chatCSS["instructor-chat"]}`}
          >
            <div className={`${chatCSS["message"]} ${chatCSS["received"]}`}>
              Hello! How can I assist you today?
            </div>
            <div className={`${chatCSS["message"]} ${chatCSS["sent"]}`}>
              Hi, I have added a new course in the Program.
            </div>
          </div>
          <div className={chatCSS["chat-input"]}>
            <input
              type="text"
              placeholder="Type your message"
              id="instructor-message"
            />
            <button>Send</button>
          </div>
        </div>
        <div className={chatCSS["chat-box"]}>
          <div className={chatCSS["chat-header"]}>
            Program Coordinator - Student Chat
          </div>
          <div
            className={`${chatCSS["chat-messages"]} ${chatCSS["student-chat"]}`}
          >
            <div className={`${chatCSS["message"]} ${chatCSS["received"]}`}>
              Hi! I'm having some trouble enrolling the course.
            </div>
            <div className={`${chatCSS["message"]} ${chatCSS["sent"]}`}>
              Sure, I can help you with that. Could you please specify the
              course name and ID?
            </div>
          </div>
          <div className={chatCSS["chat-input"]}>
            <input
              type="text"
              placeholder="Type your message"
              id="student-message"
            />
            <button>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
