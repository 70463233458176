import React from "react";
import aboutCSS from "../CSS/admin_aboutus.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const AdminAboutUs = () => {
  return (
    <div className={aboutCSS["aboutus-body"]}>
      <div className={aboutCSS["navbar"]}>
        <span className={aboutCSS["navbar-title"]}>AcademiaHub</span>
        <Link to="/login">Log in</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Home Page</Link>
        <Link to="/contactus">Contact Us</Link>
      </div>

      <h2 className={aboutCSS["aboutus-h2"]}> About Us </h2>
      <div className={aboutCSS["grid-container"]}>
        <section className={aboutCSS["aboutus-content"]}>
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>
              Welcome to Our University
            </h2>
            <p className={aboutCSS["aboutus-p"]}>
              At Our University, we are dedicated to fostering a vibrant
              community of learners, thinkers, and achievers. Our commitment to
              excellence in education, research, and innovation has made us a
              trusted institution of higher learning for countless individuals
              around the world.
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Our Campus</h2>
            <p className={aboutCSS["aboutus-p"]}>
              Nestled in the heart of a picturesque location, Our University's
              campus is a haven for intellectual exploration and personal
              growth. With state-of-the-art facilities, modern classrooms, and
              lush green spaces, our campus provides the perfect environment for
              you to thrive academically and socially.{" "}
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Academic Excellence</h2>
            <p className={aboutCSS["aboutus-p"]}>
              We take pride in our diverse range of programs, led by renowned
              faculty members who are experts in their fields. Whether you're
              pursuing a degree in science, arts, business, or any other
              discipline, you'll find rigorous academics coupled with practical
              experiences to prepare you for a successful future.
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Research and Innovation</h2>
            <p className={aboutCSS["aboutus-p"]}>
              Our University is at the forefront of cutting-edge research and
              innovation. Our students have the opportunity to engage in
              groundbreaking research projects and collaborate with top-notch
              faculty, driving innovation and contributing to the advancement of
              knowledge.
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Student Life</h2>
            <p className={aboutCSS["aboutus-p"]}>
              Beyond the classroom, Our University offers a vibrant and
              inclusive student life experience. Join clubs and organizations,
              participate in community service, or attend cultural events –
              there's something for everyone here to help you grow as a
              well-rounded individual.
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Mission and Vision</h2>
            <p className={aboutCSS["aboutus-p"]}>
              Our mission is to provide high-quality education and foster a
              community of lifelong learners. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla eget purus ut justo tincidunt
              tincidunt. Fusce consectetur quam ut purus tristique, non bibendum
              dui vulputate.
            </p>
          </div>
          <br />
          <div className={aboutCSS["grid-item"]}>
            <h2 className={aboutCSS["aboutus-h2"]}>Support and Resources</h2>
            <p className={aboutCSS["aboutus-p"]}>
              Your success is our priority. We offer comprehensive support
              services, including academic advising, career counseling, and
              wellness programs to ensure that you have all the tools you need
              to excel academically and personally.
            </p>
          </div>
          <br />
        </section>
      </div>
    </div>
  );
};

export default AdminAboutUs;
