import React from "react";
import monitorCSS from "../CSS/admin_monitor.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const ActivityPage = () => {
  return (
    <div className={monitorCSS["monitor-body"]}>
      <div className={monitorCSS["navbar"]}>
        <span className={monitorCSS["navbar-title"]}>
          Monitor User's Activity
        </span>
        <Link to="/admin_admin">Dashboard</Link>
      </div>
      <h2 className={monitorCSS["monitor-h2"]}>User Table</h2>
      <table className={monitorCSS["monitor-t1"]}>
        <thead>
          <tr>
            <th className={monitorCSS["monitor-th"]}>UserID</th>
            <th className={monitorCSS["monitor-th"]}>Role</th>
            <th className={monitorCSS["monitor-th"]}>Login</th>
            <th className={monitorCSS["monitor-th"]}>Logout</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={monitorCSS["monitor-td"]}>1002909</td>
            <td className={monitorCSS["monitor-td"]}>Student</td>
            <td className={monitorCSS["monitor-td"]}>
              September 25, 2023 3:45pm
            </td>
            <td className={monitorCSS["monitor-td"]}>-</td>
          </tr>
          <tr>
            <td className={monitorCSS["monitor-td"]}>6563277</td>
            <td className={monitorCSS["monitor-td"]}>Student</td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 8:59pm{" "}
            </td>
            <td className={monitorCSS["monitor-td"]}>-</td>
          </tr>
          <tr>
            <td className={monitorCSS["monitor-td"]}>2552627</td>
            <td className={monitorCSS["monitor-td"]}>QA Officer</td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 1:20pm{" "}
            </td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 5:30pm{" "}
            </td>
          </tr>
          <tr>
            <td className={monitorCSS["monitor-td"]}>6768322</td>
            <td className={monitorCSS["monitor-td"]}>Teacher</td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 10:50am{" "}
            </td>
            <td className={monitorCSS["monitor-td"]}>-</td>
          </tr>
          <tr>
            <td className={monitorCSS["monitor-td"]}>1779268</td>
            <td className={monitorCSS["monitor-td"]}>Student</td>
            <td className={monitorCSS["monitor-td"]}>
              September 23, 2023 5:59pm{" "}
            </td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 1:20am{" "}
            </td>
          </tr>
          <tr>
            <td className={monitorCSS["monitor-td"]}>7254356</td>
            <td className={monitorCSS["monitor-td"]}>Student</td>
            <td className={monitorCSS["monitor-td"]}>
              September 23, 2023 1:00am{" "}
            </td>
            <td className={monitorCSS["monitor-td"]}>
              September 24, 2023 1:20am{" "}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ActivityPage;
