import React from "react";
import profileCSS from "../CSS/qa_profile.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const QA_ProfilePage = () => {
  return (
    <div className={profileCSS["prof-body"]}>
      <div className={profileCSS["navbar"]}>
        <span className={profileCSS["navbar-title"]}>PROFILE</span>
        <Link to="/qa_dashboard">Go to Dashboard</Link>
      </div>
      <div className={profileCSS["profile-container"]}>
        <h1>QA Officer Profile</h1>
        <section id="profile" className={profileCSS["profile-section"]}>
          <h2>Profile Information</h2>
          <form>
            <div className={profileCSS["profile-item"]}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" value="James" />
            </div>
            <div className={profileCSS["profile-item"]}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value="james.massey@example.com"
              />
            </div>
            <div className={profileCSS["profile-item"]}>
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value="(123) 456-7890"
              />
            </div>
            <div className={profileCSS["profile-item"]}>
              <button className={profileCSS["profile-button"]} type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </section>

        <section id="change-password" className={profileCSS["profile-section"]}>
          <h2>Change Password</h2>
          <form>
            <div className={profileCSS["profile-item"]}>
              <label htmlFor="current-password">Current Password:</label>
              <input
                type="password"
                id="current-password"
                name="current-password"
              />
            </div>
            <div className={profileCSS["profile-item"]}>
              <label htmlFor="new-password">New Password:</label>
              <input type="password" id="new-password" name="new-password" />
            </div>
            <div className={profileCSS["profile-item"]}>
              <button type="submit" className={profileCSS["profile-button"]}>
                Change Password
              </button>
            </div>
          </form>
        </section>

        <section id="notifications" className={profileCSS["profile-section"]}>
          <h2>Notification Preferences</h2>
          <div className={profileCSS["profile-item"]}>
            <label htmlFor="email-notifications">
              Receive Email Notifications:
            </label>
            <input
              type="checkbox"
              id="email-notifications"
              name="email-notifications"
            />
          </div>
          <div className={profileCSS["profile-item"]}>
            <label htmlFor="sms-notifications">
              Receive SMS Notifications:
            </label>
            <input
              type="checkbox"
              id="sms-notifications"
              name="sms-notifications"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default QA_ProfilePage;
