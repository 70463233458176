import React from "react";
import gradeCSS from "../CSS/instructor_grade_exam.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const InstructorGradeExam = () => {
  return (
    <div className={gradeCSS["grade-body"]}>
      <div className={gradeCSS["navbar"]}>
        <span className={gradeCSS["navbar-title"]}>Grade Exam</span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_message">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <h1 className={gradeCSS["grade-h1"]}>Grade Exam</h1>
      <table className={gradeCSS["grade-table"]}>
        <thead>
          <tr>
            <th className={gradeCSS["grade-th"]}>Exam</th>
            <th className={gradeCSS["grade-th"]}>Topics</th>
            <th className={gradeCSS["grade-th"]}>Availability</th>
            <th className={gradeCSS["grade-th"]}>Exam Status</th>
            <th className={gradeCSS["grade-th"]}>Grading Status</th>
            <th className={gradeCSS["grade-th"]}>Posting</th>
            <th className={gradeCSS["grade-th"]}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 1</td>
            <td className={gradeCSS["grade-td"]}>
              Html Basics
              <br />
              Tables and Forms
            </td>
            <td className={gradeCSS["grade-td"]}>09-20-2023 to 09-23-2023</td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Taken
            </td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Graded
            </td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Posted
            </td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>View Result</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 2</td>
            <td className={gradeCSS["grade-td"]}>
              CSS Basics
              <br />
              Layout
            </td>
            <td className={gradeCSS["grade-td"]}>09-23-2023 to 09-25-2023</td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Taken
            </td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Graded
            </td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Posted
            </td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>Post Result</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 3</td>
            <td className={gradeCSS["grade-td"]}>
              Java Script <br />
              Fundamentals
            </td>
            <td className={gradeCSS["grade-td"]}>09-25-2023 to 09-27-2023</td>
            <td
              className={`${gradeCSS["status-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Taken
            </td>
            <td
              className={`${gradeCSS["status-not-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Graded
            </td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>Grade Exam</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 4</td>
            <td className={gradeCSS["grade-td"]}>
              Java Script 2<br />
              Javascript 3
            </td>
            <td className={gradeCSS["grade-td"]}>10-01-2023 to 10-05-2023</td>
            <td
              className={`${gradeCSS["status-not-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Taken
            </td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>Post Exam</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 5</td>
            <td className={gradeCSS["grade-td"]}>
              React
              <br />
              Server Side Programming: PHP
            </td>
            <td className={gradeCSS["grade-td"]}>10-07-2023 to 10-09-2023</td>
            <td
              className={`${gradeCSS["status-not-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Taken
            </td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>Post Exam</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 6</td>
            <td className={gradeCSS["grade-td"]}>
              Server side development: Node.js
              <br />
            </td>
            <td className={gradeCSS["grade-td"]}>10-11-2023 to 10-15-2023</td>
            <td
              className={`${gradeCSS["status-not-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Taken
            </td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>
              <button className={gradeCSS["grade-button"]}>Post Exam</button>
            </td>
          </tr>
          <tr>
            <td className={gradeCSS["grade-td"]}>Exam 7</td>
            <td className={gradeCSS["grade-td"]}>
              Working with databases
              <br />
            </td>
            <td className={gradeCSS["grade-td"]}>10-20-2023 to 10-25-2023</td>
            <td
              className={`${gradeCSS["status-not-submitted"]} ${gradeCSS["grade-td"]}`}
            >
              Not Created
            </td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>-</td>
            <td className={gradeCSS["grade-td"]}>
              <Link to="/instructor_create_exam">
                <button className={gradeCSS["grade-button"]}>
                  Create Exam
                </button>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InstructorGradeExam;
