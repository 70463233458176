import React from "react";
import examCSS from "../CSS/instructor_create_exam.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary


const InstructorCreateExam = () => {
  return (
    <div className={examCSS["exam-body"]}>
      <div className={examCSS["navbar"]}>
        <span className={examCSS["navbar-title"]}>Create Exam </span>
        <Link to="/login">Signout</Link>
        <Link to="/instructor_profile">My Profile</Link>
        <Link to="/instructor_dashboard">Dashboard</Link>
        <Link to="/instructor_notifications">Notifications</Link>
        <Link to="/instructor_message">Message</Link>
        <Link to="/instructor_announcement">Announcement</Link>
      </div>
      <div className={examCSS["exam-container"]}>
        <h2>Create Exam</h2>
        <form action="submit_exam.php" method="post">
          <label htmlFor="examTitle">Exam Title:</label>
          <input type="text" id="examTitle" name="examTitle" required />
          <br />
          <br />
          <label htmlFor="examDate">Exam Date:</label>
          <input type="text" id="examDate" name="examDate" required />
          <br />
          <br />
          <label htmlFor="question1">Question 1:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 2:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 3:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 4:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 5:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 6:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 7:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 8:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 9:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <label htmlFor="question1">Question 10:</label>
          <input type="text" id="question1" name="question1" required />
          <br />
          <label htmlFor="option1_1">Option 1:</label>
          <input type="text" id="option1_1" name="option1_1" required />
          <br />
          <label htmlFor="option1_2">Option 2:</label>
          <input type="text" id="option1_2" name="option1_2" required />
          <br />
          <label htmlFor="correct1">Correct Answer:</label>
          <input type="text" id="correct1" name="correct1" required />
          <br />
          <br />
          <input type="submit" value="Create Exam" />
          <input type="submit" value="Post Exam" />
        </form>
      </div>
    </div>
  );
};

export default InstructorCreateExam;
