import React from "react";
import courseCSS from "../CSS/student_courses.module.css";
import { Link } from 'react-router-dom'; // Make sure to adjust the import path if necessary

const Std_Courses = () => {
  return (
    <div className={courseCSS["courses-body"]}>
      <div className={courseCSS["navbar"]}>
        <span className={courseCSS["mycourses-navbar-title"]}>My Courses</span>
        <Link to="/login">Signout</Link>
        <Link to="/student_profile">My Profile</Link>
        <Link to="/student_courses">MyCourses</Link>
        <Link to="/student_notifications">Notifications</Link>
        <Link to="/student_course_search">Search</Link>
        <Link to="/student_message">Message</Link>
      </div>
      <br />
      <br />
      <br />
      <div className={courseCSS["tile"]}>
        <h2>CSE 5355 WEB DATA MANAGEMENT</h2>
        <div className={courseCSS["dropdown"]}>
          <button className={courseCSS["dropbtn"]}>Go to Content</button>
          <div className={courseCSS["dropdown-content"]}>
            <Link to="/student_coursematerial">Course Material</Link>
            <Link to="../FILES/student_coursesyllabus.docx">Syllabus</Link>
            <Link to="/student_examschedule">Exams/Assessments</Link>
            <Link to="/student_feedback">Give Feedback</Link>
          </div>
        </div>
      </div>
      <div className={courseCSS["tile"]}>
        <h2>CSE 6543 ADV SOFTWARE ENGINEERING</h2>
        <div className={courseCSS["dropdown"]}>
          <button className={courseCSS["dropbtn"]}>Go to Content</button>
          <div className={courseCSS["dropdown-content"]}>
            <Link to="#">Course Material</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Exams/Assessments</Link>
            <Link to="#">Give Feedback</Link>
          </div>
        </div>
      </div>
      <div className={courseCSS["tile"]}>
        <h2>CSE 6112 MACHINE LEARNING</h2>
        <div className={courseCSS["dropdown"]}>
          <button className={courseCSS["dropbtn"]}>Go to Content</button>
          <div className={courseCSS["dropdown-content"]}>
            <Link to="#">Course Material</Link>
            <Link to="#">Syllabus</Link>
            <Link to="#">Exams/Assessments</Link>
            <Link to="#">Give Feedback</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Std_Courses;
